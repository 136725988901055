












































import { bus } from '@/main';
import { Component, Vue } from 'vue-property-decorator';
import RichTextObject from '@/components/RichTextObject.vue';
import ImageCover from '@/components/ImageCover.vue';
import { LightGallery } from 'vue-light-gallery';
 //import FsLightbox from "fslightbox-vue";

@Component({
    components:{
        RichTextObject, 
        LightGallery, 
        ImageCover, 
   //     FsLightbox
    }
})
export default class Post extends Vue {
    data: any | null = null;
    galleryImageIndex: number | null = null;
    toggler = false;

    get media(){ 
        if (!this.data){
            return [];
        }
        if (this.data.content.images.length < 2){
            return [];
        }

        return this.data.content.images.map((i: any) => { 
            return { 
                thumb: i.filename.replace('//a.storyblok.com','//img2.storyblok.com/0x100/filters:quality(60)'), 
                src: i.filename, 
            }
        })
    }
    get galleryImages(){
        if (!this.data){
            return [];
        }
        if (this.data.content.images.length < 2){
            return [];
        }
        return this.data.content.images.map( (i: any) => i.filename);
    }
    goTo404Page(){ 
        this.$router.push('/not-found');
    }

    filenameOnly(filename: string){
        const splitValues = filename.split('/');
        if (!splitValues.length){
            return '';
        }
        return splitValues[splitValues.length-1];

    }

    async mounted(){ 
        bus.$emit('showBusy');
        let slug = this.$route.params.slug;
        

        if (!slug){ 
            slug = 'home';
        }
        
        try{
            this.data = await this.$store.dispatch('getPost', slug);

            bus.$emit('setHeader',{
                title: null, 
                subtitle: null,
                buttons: null,
                bgVideo: null
            })
        }catch(err){ 
            this.goTo404Page();
        }

        bus.$emit('hideBusy');

        if (!this.data){
            this.goTo404Page();
            return;
        }
    }
    
}
